<template>
  <div>
    <span>{{ item.value }}</span>
  </div>
</template>

<script>
export default {
  props: {
    item: { required: true }
  }
};
</script>
