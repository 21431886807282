<template>
  <FveFieldTemplate>
    <VueSuggestion
      :items="items"
      v-model="item"
      :setLabel="setLabel"
      :itemTemplate="FveSelectAjaxV2AddressItem"
      @changed="inputChange"
      placeholder="Поиск по адресу"
      inputClasses="my-autocompleate__input"
    />
  </FveFieldTemplate>
</template>

<script>

import GetGoogleMap from "@library/GetGoogleMap";

import { VueSuggestion } from 'vue-suggestion';
import FveSelectAjaxV2AddressItem from './FveSelectAjaxV2AddressItem.vue';
import FveFieldMixin from "@widgetFormValidate/src/Mixin/FveFieldMixin";


export default {
  mixins: [
    FveFieldMixin
  ],
  components: {
    VueSuggestion
  },
  props: {
    value: Object,
  },
  data () {
    return {
      items: [],
      FveSelectAjaxV2AddressItem
    };
  },
  computed: {
    item: {
      get() { return this.value; },
      set(v) {
        this.inputFormElement(v);
      }
    }
  },
  methods: {
    prepareValue($event) {
      return $event;
    },
    isEmpty(value) {
      return !value || !value.value;
    },
    validateFunction(str) {
      if(!str.data){
        return 'Не удалось определить координаты';
      }
      return 'SUCCESS';
    },

    setLabel (item) {
      return item.value;
    },


    requestGoogle(query) {
      GetGoogleMap().then( ({autoComplete}) => {

        autoComplete.getPlacePredictions({
          input: query,
        }, (data) => {
          this.items = data.map((obj) => {
            return {
              value: obj.description,
              data: {
                geo_lat: null,
                geo_lon: null,
              }
            };
          });
        });
      });

    },
    requestDadata(query) {
      var url = "https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address";
      var token = "6c37d4ab468900098a317f363de9ab453e6ab60c";

      var options = {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
          "Authorization": "Token " + token
        },
        body: JSON.stringify({query: query})
      };

      return fetch(url, options).then(response => response.json())
        .then(result => {
          this.items = result.suggestions;
          return result.suggestions;
        })
        .catch(error => console.log("error", error));
    },

    request(query){
      return this.requestGoogle(query);
    },

    inputChange (text) {

      this.inputFormElement({
        value: text,
        data: { geo_lat: null, geo_lon: null }
      });
      if (text.length < 2) {
        return;
      }

      this.request(text);
    }
  }
};
</script>

<style lang="scss" scope>
.vue-suggestion {
  @import "~@widgetFormValidate/style/inputText.scss";
  input {
    margin-bottom: 0 !important;
  }
  margin-bottom: 24px;
}
.vue-suggestion .vs__list {
  width: 100%;
  text-align: left;
  border: none;
  border-top: none;
  max-height: 400px;
  overflow-y: auto;
  border-bottom: 0px solid black;
  position: relative;
  z-index: 5000;


  background        : var(--fve-input--background       );
  background-color  : var(--fve-input--background-color );
}
.vue-suggestion .vs__list .vs__list-item {

  background-color  : var(--br-block-prime) !important;

  //background-color: #fff;
  border-left: 1px solid var(--br-block-prime);
  border-right: 1px solid var(--br-block-prime);

  color: #ACACAC;
  text-overflow: ellipsis;
  overflow: hidden;

  display: block;
  padding: 12px;
  min-height: 40px;
  line-height: 16px;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  position: relative;
  cursor: pointer;
  white-space: nowrap;
}
.vue-suggestion .vs__list .vs__list-item:last-child {
  border-bottom: none;
}

.vue-suggestion .vs__list .vs__list-item.vs__item-active,
.vue-suggestion .vs__list .vs__list-item:hover {
  color: #343A40;
  background: var(--color-prime)!important;
}


.vue-suggestion .vs__list,
.vue-suggestion .vs__loading {
  position: absolute;
}
.vue-suggestion .vs__selected{
  padding: 0;
  border: 0;
  margin: 0;
}
</style>
