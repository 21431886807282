<template>
  <div class="add-parameter">
    <div class="add-parameter__list">
      <slot/>
    </div>
    <b-button size="lg" variant="primary" class="mb-md" @click="$emit('add')">Добавить параметр</b-button>
  </div>
</template>

<script>

export default {

};
</script>

<style lang="scss" scoped>

</style>
