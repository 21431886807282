
const addScript = (src) => {
  let script = document.createElement('script');
  script.type = 'text/javascript';
  script.async = true;
  script.src = src;
  var s = document.getElementsByTagName('script')[0];
  s.parentNode.insertBefore(script, s);
};


let mapInit;

const GetGoogleMap = () => {
  if ( !mapInit ) {
    mapInit = new Promise(function(mapResolve) {
      global.googleMapCallback = () => {

        const autoComplete = new global.google.maps.places.AutocompleteService();
        const geocoder = new global.google.maps.Geocoder();
        mapResolve({
          autoComplete: autoComplete,
          getAddressCoordinate(address){
            return new Promise(function(resolve, reject) {
              geocoder.geocode( { 'address': address}, (results, status) => {
                if (status == 'OK') {
                  const location = results[0].geometry.location;
                  resolve({lat: location.lat(), lon: location.lng()});
                } else {
                  reject();
                }
              });
            });
          },
          geocoder: geocoder,

        });
      };
      const googleMapKey = 'AIzaSyBotZVFsrZEjLm4PnFs5vRCMVtXIqtCFO4';
      addScript(`https://maps.googleapis.com/maps/api/js?key=${googleMapKey}&libraries=places&callback=googleMapCallback`);
    });
  }
  return mapInit;
};

export default GetGoogleMap;
