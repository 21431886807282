<template>
  <form class="form">

    <FveBlockSwitchActive
        v-if="editForm"
        v-model="form.is_active"
        class="fix-swich"
    />

    <fieldset class="form__fieldset">
      <div class="row disable___row--fix">
        <div class="col-12">
          <FveImageMultiplePreview
            name="name"
            v-model="form.courtmedia_set"

            @removeImage="$emit('removeImage', $event)"
          />
        </div>
        <div class="col-12">
          <FveSelectAjaxV2Address
            label="Адрес"
            name="adress"
            required
            v-model="form.address"
          />
          <span
            v-if="!addressFormDetails"
            @click="addressFormDetails=!addressFormDetails"
            style="cursor: pointer; color: var(--text-descr); position: relative; top: -20px;"
          >Редактировать вручную</span>

          <FveSelectAjaxV2AddressUser
            v-if="addressFormDetails"
            label="Детали адреса"
            required
            v-model="form.address"
          />

        </div>
        <div class="col-12">
          <FveText
            label="Название"
            name="name"
            required
            v-model="form.name"
          />
        </div>
        <div class="col-3">
          <FveText
            label="Высота потолка"
            name="ceiling"
            v-model="form.ceiling"
          />
        </div>
        <div class="col-3">
          <FveText
            label="Размер помещения"
            name="space"
            v-model="form.space"
          />
        </div>
        <div class="col-3">
          <FveText
            label="Вместимость"
            name="capacity"
            v-model="form.capacity"
          />
        </div>
        <div class="col-3">
          <FveSelectValue
            label="Пол"
            name="floor_type"
            :optionGetKey="(option) => option.key"
            :optionGetName="(option) => option.value"
            v-model="form.floor_type"
            :optionList="floorTypeList"
          />
        </div>
      </div>

      <!--
      <AddParameter @add="addParams">
        <template v-for="(item, index) in form.params">
          <div class="row row--fix" :key="index">
            <div class="col-6">
              <FveText
                label="Параметр"
                name="parameter"
                v-model="form.params.key"
              />
            </div>
            <div class="col-5">
              <FveText
                label="Значение"
                name="value"
                v-model="form.params.value"
              />
            </div>
            <div class="col-1">
              <div class="remove-btn mt-fix" @click="removeParams(index)">
                <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 12.81 13.83" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M6.38 13.83H3.76A2.22 2.22 0 011.5 11.7C1.29 9.8.93 5.33.93 5.29a.75.75 0 111.5-.12s.36 4.49.57 6.36c.07.67.46.77.8.77h5.19a.75.75 0 00.84-.78c.21-1.86.57-6.31.57-6.36a.76.76 0 01.81-.69.75.75 0 01.69.81s-.37 4.52-.58 6.4A2.24 2.24 0 019 13.81zM12.06 3.83H.75a.75.75 0 010-1.5h11.31a.75.75 0 010 1.5z"/><path d="M9.88 3.83a1.85 1.85 0 01-1.81-1.49l-.17-.81a.2.2 0 00-.09 0H5a.1.1 0 00-.1.08l-.15.76a1.85 1.85 0 01-1.82 1.46.75.75 0 010-1.5.35.35 0 00.34-.33l.16-.81A1.62 1.62 0 015 0h2.81a1.6 1.6 0 011.55 1.19l.18.81a.35.35 0 00.34.28.75.75 0 110 1.5z"/></svg>
              </div>
            </div>
          </div>
        </template>

      </AddParameter>
      -->
    </fieldset>
  </form>
</template>

<script>
import AddParameter from "@component/Module/AddParameter";
import FveText from "@widgetFormValidate/src/Element/Text/FveText";
import FveSelectValue from "@widgetFormValidate/src/Element/Select/vue-multiselect/FveSelectValue";
import FveImageMultiplePreview from '@widgetFormValidate/src/Element/File/FveImageMultiplePreview';
import FveSelectAjaxV2Address from "@widgetFormValidate/src/Element/Custom/FveSelectAjaxV2Address";
import FveSelectAjaxV2AddressUser from "@widgetFormValidate/src/Element/Custom/FveSelectAjaxV2AddressUser";
//
import FveFormMixin from "@widgetFormValidate/src/Mixin/FveFormMixin";
import FveBlockSwitchActive from "@widgetFormValidate/src/Element/Switch/FveBlockSwitchActive";

export default {
  mixins: [
    FveFormMixin
  ],
  props: {
    editForm: {type: Boolean, default: false}
  },
  components: {
    FveBlockSwitchActive,
    //AddParameter,
    FveText,
    FveSelectValue,
    FveImageMultiplePreview,
    FveSelectAjaxV2Address,
    FveSelectAjaxV2AddressUser,
  },
  data() {
    return {
      addressFormDetails: false,
      floorTypeList: [],
    };
  },
  methods: {
    formSchema() {
      return {
        //
        is_active    : { type: Boolean, default: () => { return true; } },
        //
        courtmedia_set  : { type: Array,  default: () => { return []; } },
        address         : { type: Object, default: () => { return null; } },
        name            : { type: String, default: () => { return ''; } },
        ceiling    : { type: [String, Number], default: () => { return ''; } },
        space      : { type: [String, Number], default: () => { return ''; } },
        capacity   : { type: [String, Number], default: () => { return ''; } },
        floor_type : { type: String, default: () => { return ''; } },
        //params          : { type: Array,  default: () => { return [{key: '', value: ''}]; } },
      };
    },
    addParams() {
      this.form.params.push({key: '', value: ''});
    },
    removeParams(index) {
      let arr = this.form.params;
      this.form.params = [...arr.slice(0, index), ...arr.slice(index + 1, arr.length)];
    },
  },
  mounted() {
    RequestManager.Reference.getFloorTypeList({}).then((result) => {
      this.floorTypeList = result;
    });
  }
};

</script>

<style lang="scss" scoped>

// remove-btn
.remove-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
  svg {
    width: 22px;
    height: auto;
    cursor: pointer;
    path {
      fill: var(--text-descr);
      transition: all .2s ease-in-out;
    }
    &:hover {
      path {
        fill: var(--color-red);
      }
    }
  }
}
</style>
