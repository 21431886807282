<template>
  <FveFieldTemplate>
    <div class="page-filter" style="margin-bottom: 20px; padding: 16px 12px; border-radius: 8px; background-color: var(--br-block-prime);">
      <div class="row">
        <div class="col-12 col-md-4">
          <FveText
            label="Название"
            v-model="address_name"
          />
        </div>
        <div class="col-12 col-md-4">
          <FveNumber
            label="Широта"
            v-model="address_lat"
          />
        </div>
        <div class="col-12 col-md-4">
          <FveNumber
            label="Долгота"
            v-model="address_lon"
          />
        </div>
      </div>
    </div>
  </FveFieldTemplate>
</template>

<script>

import FveFieldMixin from "@widgetFormValidate/src/Mixin/FveFieldMixin";

import FveText from "@widgetFormValidate/src/Element/Text/FveText";
import FveNumber from "@widgetFormValidate/src/Element/Text/FveNumber";

export default {
  mixins: [
    FveFieldMixin
  ],
  components: {
    FveText,
    FveNumber,
  },
  props: {
    // значение по умолчанию (можно переопределить тип)
    value    : { type: Object },
  },
  computed: {
    address_name: {
      get() { return this.value ? this.value.value : ''; },
      set(val) {
        this.setValue(val, this.address_lat, this.address_lon);
      },
    },
    address_lat: {
      get() { return (this.value && this.value.data && this.value.data.geo_lat) ? this.value.data.geo_lat : ''; },
      set(val) {
        this.setValue(this.address_name, val, this.address_lon);
      },
    },
    address_lon: {
      get() { return (this.value && this.value.data && this.value.data.geo_lon) ? this.value.data.geo_lon : ''; },
      set(val) {
        this.setValue(this.address_name, this.address_lat, val);
      },
    },
  },
  methods: {
    setValue(nane, lat, lon) {
      this.$emit('input', { value: nane, data: {geo_lat: lat, geo_lon: lon} });
    },

    prepareValue($event) {
      return $event.target.value;
    },
    isEmpty(value) {
      return value === '';
    },
    // eslint-disable-next-line
    validateFunction(str) {
      return 'SUCCESS';
    },
  }
};
</script>

<style lang="scss" scoped>

@import "~@widgetFormValidate/style/inputText.scss";

</style>
